import React from 'react';
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha"

export default class Contact extends React.Component {

  constructor() {
    super();
    this.state = {
        email:"",
        name:"",
        message:"",
        status: "",
        _gotcha: ""
    }
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  submit(ev) {
    ev.preventDefault();
    const form = ev.target.form;
    const data = new FormData(form);
    
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };

    xhr.send(data);
  }
  
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
}   

  render() {
      const {status} = this.state
      const {formSpree} = this.props
    return (
        <form action={formSpree} method="POST" >
        <div className="row gtr-uniform">
          <div className="col-6 col-12-xsmall">
            <input type="text" onChange={this.handleChange} name="name" id="name" placeholder="Name" />
          </div>
          <div className="col-6 col-12-xsmall">
            <input type="email" onChange={this.handleChange} name="email" id="email" placeholder="Email" />
          </div>
          <div className="col-6 col-12-xsmall" style={{display:"none"}}>
            <input type="text" onChange={this.handleChange} name="_gotcha" id="_gotcha" placeholder="description" />
          </div>
          <div className="col-12">
            <textarea
              name="message"
              id="message"
              placeholder="Message"
              rows="4"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-12" style={{display:"flex", "justifyContent": "center"}}>
            <ReCaptcha action="homepage" siteKey="6LfKkMshAAAAAGWretSm6kSN6kq1uGFAKy7Iy7_p" size="normal" badge="bottomright" />
          </div>
          <div className="col-12">
            <ul className="actions special">
              <li>
                {status === "SUCCESS" ? <p>Thanks!</p> : 
                    <input
                    type="submit"
                    value="Send Message"
                    className="primary"
                    onClick={this.submit}
                    />
                }
                {status === "ERROR" && <p>Ooops! There was an error.</p>}
              </li>
            </ul>
          </div>
        </div>
        
      </form>
    );
  }
}
