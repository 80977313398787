import React, {useState} from 'react';
import Scrollspy from 'react-scrollspy';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Scroll from '../components/Scroll';
import overLayPic from '../assets/images/overlay.png';
import Contact from '../components/Form'



const sections = [
  { id: 'one', bg: require('../assets/images/pic06.png') },
  { id: 'two', bg: require('../assets/images/pic02.jpg') },
  { id: 'three', bg: require('../assets/images/pic03.jpg') },
];

const backgrounds = [
  `url('${require('../assets/images/SG-backgrounds/floral 3 3000x3000.jpg')}')`,
  `url('${require('../assets/images/SG-backgrounds/floral 4 3000x3000.jpg')}')`,
  `url('${require('../assets/images/SG-backgrounds/floral 5 3000x3000.jpg')}')`,
  `url('${require('../assets/images/SG-backgrounds/floral 8 3000x3000.jpg')}')`,
  `url('${require('../assets/images/SG-backgrounds/floral 9 3000x3000.jpg')}')`,
  `url('${require('../assets/images/SG-backgrounds/floral 10 3000x3000.jpg')}')`,
]
const backgroundIndex =  Math.floor((Math.random() *6) + 0);
const background = backgrounds[backgroundIndex];


const IndexPage = () => {
  
  const [individualModalOpen, setIndividualModalOpen] = useState(false);
  const [couplesModalOpen, setCouplesModalOpen] = useState(false);
  const [meditationModalOpen, setMeditationModalOpen] = useState(false);
  const [yogaModalOpen, setYogaModalOpen] = useState(false);

 
  console.log()
  return <Layout background={background}> 

    <div id="main-header" style={{color:"#545454"}}>call today to schedule your appointment 248.906.8547</div>
    
    <section id="one" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[0].bg} alt="" />
        </span>
        <div className="content">
          <img src={require("../assets/images/sp.jpg")} style={{width:"128px", borderRadius:"50%"}}/>
          <header className="major">
            <h2>Thank You</h2>
          </header>
            <p>Your payment has been received. <br/>You will receive an email with your receipt shortly.</p>
            <div className="row" style={{justifyContent:"center"}}>
                
                
                  <a style={{position:"relative", left:"2%"}}href="/" className="button primary">View my website</a>
                
                
                
              </div>
            
        </div>
        <Scroll type="id" element="footer">
          <a href="#footer" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>




    <section id="footer">
      <div className="container">
        <header className="major">
          <h2>Get in touch</h2>
        </header>
        <Contact formSpree="https://formspree.io/xoqlowlr" />
        <p><a href="tel:12489068547">Or call today! 248.906.8547</a></p>
      </div>
      <Footer />
    </section>
    <Scrollspy
      items={sections.map(s => s.id)}
      currentClassName="active"
      offset={50}
      componentTag={'div'}
    >
      {sections.map(s => {
        return (
          <div
            key={s.id}
            className="main-bg"
            id={`${s.id}-bg`}
            style={{ backgroundImage: `url(${overLayPic}), url(${s.bg})` }}
          />
        );
      })}
    </Scrollspy>
  </Layout>
}

export default IndexPage;
